import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout/Layout'
import Blocks from '@/lib/blocks/Blocks'
import MetaInfo from '@/layout/MetaInfo'

const BlogPost = ({ data: { pg, post }, location }) => (
	<Layout>
		<Blocks blocks={pg.blocks} post={post} location={location} />
	</Layout>
)

export default BlogPost

export const Head = MetaInfo

export const query = graphql`query ($id: String) {
	pg: sanityBlogTemplate(metaInfo: {slug: {current: {eq: "blog/*"}}}) {
		blocks {
			...blogPostContent
			...blogNext
			...blogSearch
			...footerCtaSection
		}
	}
	post: sanityBlogPost(id: {eq: $id}) {
		_id
		title
		_rawContent
		excerpt
		featuredImage { asset { gatsbyImageData(placeholder: NONE, width: 800) } }
		thumbnailOnly
		author {
			name
		}
		categories {
			name
		}
		publishDate
		metaInfo { ...metaInfo }
	}
}`
